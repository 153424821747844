import {UserAgent} from 'express-useragent'

const shouldLogF = () => {
  if (window.location.origin.startsWith("http")) {
    //return true
  }
  const params = new URLSearchParams(window.location.search)
  const result = params.get("enableLogging")
  console.log("shouldLog", result)
  return result
}

const shouldLog = shouldLogF()
const consoleLog = (...args) => {
  if (shouldLog) {
    console.log(...args)
  }
}

const isChrome1 = !!window.chrome 
console.log("isChrome1", isChrome1)

const isNative = window.ReactNativeWebView

let userAgent
function getUserAgent() {
  if (!userAgent) {
    userAgent = new UserAgent().parse(navigator.userAgent)
    userAgent.isSafari = userAgent.isSafari || (isNative && (userAgent.isiPhone || userAgent.isiPad || userAgent.isMac))
  }
  return userAgent
}


console.log("userAgent: ", getUserAgent())

//alert(JSON.stringify(getUserAgent()));


function isMobile() {
  const userAgent = getUserAgent()
  return userAgent.isMobile && !(isIPad() || userAgent.isAndroidTablet)
}

function isTabletOrMobile() {
    return isTablet() || isMobile(true)
}

function isTablet() {
    const userAgent = getUserAgent()
    const result = userAgent.isiPad || userAgent.isAndroidTablet || userAgent.isTablet
    if (!result) {
        if (userAgent.isSafari && window.innerWidth < window.innerHeight) { // simulator
            return true
        }
    }
    return result
}

function isApple() {
    const ua = getUserAgent()
    return ua.isMac || ua.isIPhone
}

function isIOS() {
  return (isMobile() || isIPad()) && getUserAgent().isSafari
}

function isSafari() {
  if (window.chrome) return false
  return getUserAgent().isSafari
}

function isWindows() {
    return getUserAgent().isWindows
}

function isChrome() {
    return getUserAgent().isChrome || isChrome1
}

function isFirefoxDesktop() {
    return getUserAgent().isFirefox && isDesktop()
}

function isDesktop() {
  return getUserAgent().isDesktop && !isIPad()
}

function isEdge(){
    return getUserAgent().isEdge
}

function isIPad() {
  const userAgent = getUserAgent()
  return (navigator.platform.startsWith('Mac') && navigator.maxTouchPoints > 2) || userAgent.isiPad
}

function hasSoftKeyboard() {
    return userAgent.isMobile || userAgent.isiPad || userAgent.isAndroidTablet
}

function isAndroid() {
  return userAgent.isAndroid
}

export {hasSoftKeyboard, isAndroid, isTabletOrMobile, isTablet, isIPad, isApple, isMobile, isIOS, isSafari, isChrome, isDesktop, isFirefoxDesktop, isEdge, isWindows, shouldLog, consoleLog }
