import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isDesktop } from '../../classes/Platform.js'
import { Account, UI, Purchase, SignUp, VerificationCode } from '../SignUp'
import { Laugh } from '../Laugh'
import { PlainButton } from '../Widgets'
import Cart from '../../assets/widgets/Cart.svg'
import User from '../../assets/widgets/User.svg'
import { delay} from '../../classes/Util.js'
import './index.css'
import phone from 'phone'
import googleLibPhoneNumber from 'google-libphonenumber'

const PNF = googleLibPhoneNumber.PhoneNumberFormat;
const AsYouTypeFormatter = googleLibPhoneNumber.AsYouTypeFormatter
const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance()

export const parsePhoneNumber = number => {
  try {
    const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
    return {countryCode: parsed.getCountryCode(), phoneNumber: ''+parsed.getNationalNumber()};
  } catch (ignored) {
    return {countryCode: 1, phoneNumber: number};
  }
}

export class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      form: {countryCode: 1},
      paymentMethodError: null,
    }
  }

  getForm = () => this.state.form
  set = (key, value) => this.onChange(key, value)

  onChange = (key, value) => {
    this.state.form[key] = value
    this.state.formErr = undefined
    this.forceUpdate()
  }

  backToPhoneNumber = async () => {
    await delay(0.3)
    this.ui.back()
    await delay(0.2)
    this.clearForm(true)
  }

  signOut = async () => {
    await this.props.me.signOut()
    await this.props.me.signInAnonymously()
  }

  deleteAccount = async () => {
    await this.props.me.deleteAccount()
    this.ui.gotoApp()
  }

  backToApp = async () => {
    await delay(0.3)
    this.ui.gotoApp()
  }

  getVerificationCode = async () => {
    this.ui.gotoVerificationCodeInput()
  }

  signIn = async () => {
    const form = this.getForm()
    let err
    if (!form.email) {
      err = {field: 'email', message: 'Email address is required'}
      this.setState({formErr: err})
      return
    }
    if (!form.password) {
      err = {field: 'password', message: 'Password is required'}
      this.setState({formErr: err})
      return
    }
    try {
      await this.props.me.signIn(form.email, form.password)
      this.setState({
        needsSignIn: false
      })
    } catch (err) {
      console.log(err)
      const message = err.message;
      let formErr
      if (err.code == "auth/user-not-found") {
        formErr = {field: 'email', message: "No user with that email."};
      } else if (err.code == "auth/too-many-requests") {
        formErr = {field: 'email', message: "Too many attempts. Try again later."};
      } else if (err.code == "auth/wrong-password") {
        formErr = {field: 'password', message: "Password incorrect."};
      } else if (err.code == "auth/network-request-failed") {
        formErr = {message: "You are not connected to the Internet."};
      } else if (err.code == "auth/invalid-verification-code") {
        formErr = {message: "Invalid verification code."};
      } else if (err.code) {
        formErr = {field: '', message: err.message};
      } else {
        return Promise.reject(err);
      }
      this.setState({
        formErr
      })
    }
  }

  signInComplete = () => {
    this.clearForm()
    if (!this.handlePostSignIn()) {
      this.ui.gotoApp()
    }
  }

  signUp = async () => {
    if (this.props.me.isSignedIn()) {
      return this.signInComplete()
    }
    const form = this.getForm()
    this.state.formErr = {}
    if (!form.phoneNumber) {
      this.setState({
        formErr: {
          field: 'phoneNumber',
          message: 'Phone number is required.'
        }
      })
      await delay(0.3)
      return
    }
    let phoneNumber = form.phoneNumber
    const converted = phone(phoneNumber);
    if (!converted.length) {
      this.state.formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    const handleError = err => {
      form.busy = false
      let message = err.message
      let formErr
      if (err.code === 'auth/quota-exceeded') {
        message = 'Too many sign-in attempts from this number. Try again later.'
      } else if (err.code == "auth/too-many-requests") {
        message = 'Too many attempts. Try again later.'
      } else if (err.code == 'auth/internal-error') {
        message =  'Internal Error. Try again later.'
      } else if (err.code == "auth/network-request-failed") {
        message =  'You are not connected to the Internet.'
      } else if (err.code == "auth/invalid-verification-code") {
        formErr = {message: "Invalid verification code."};
      }  else {
        //debugger
      }
      if (!formErr) {
        formErr = { field: 'phoneNumber', message: message }
      }
      this.setState({
        formErr 
      })
    }
    if (!form.phoneNumberChecked) {
      form.phoneNumberChecked = true
      form.busy = true
      this.forceUpdate()
      try {
        const { exists } = await this.props.me.phoneNumberExists(phoneNumber)
        form.busy = false
        form.isSignUp = !exists
        this.forceUpdate()
      } catch (err) {
        console.error(err)
        handleError(err)
        return
      }
    }
    if (form.phoneNumberChecked && form.referralCode && !form.isSignUp) {
      this.state.formErr = {field: 'referralCode', message: 'Referral only available for new accounts'};
      this.forceUpdate()
      return
    }
    if (!form.verified) {
      if (!form.onVerificationCodeInput) {
        if (!this.verifier) {
          this.verifier = new this.props.me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
            size: "invisible",
            callback: response => {
            }
          });
          this.recaptchaFun.style.display = "";
        }
        const recaptcha = this.verifier;
        console.log("sign in with phone number", phoneNumber)
        let result
        form.busy = true
        this.forceUpdate()
        try {
          result = await this.props.me.signInWithPhoneNumber(phoneNumber, recaptcha)
        } catch (err) {
          console.error(err)
          form.busy = false
          handleError(err)
          return
        }
        this.getVerificationCode()
        form.busy = false
        console.log(result)
        form.onVerificationCodeInput = async code => {
          try {
            form.busy = true
            this.forceUpdate()
            console.log('before confirm', result, code)
            const result1 = await result.confirm(code)
            console.log('after confirm', result1)
            form.busy = false
            form.user = result1.user
            form.onPasswordInput = null;
            form.onVerificationCodeInput = null;
            form.verified = true
            this.signInComplete()
          } catch (err) {
            console.error('confirm exception', err)
            handleError(err)
            return
          }
        }
        this.forceUpdate()
      } else {
        if (!form.verificationCode) {
          this.state.formErr = {field: 'verificationCode', message: "Verification code is required."};
          this.forceUpdate()
          return
        }
        form.busy = true
        await form.onVerificationCodeInput(form.verificationCode)
        this.forceUpdate()
      }
    } else {
      /*
      if (!form.displayName) {
        const err = {field: 'displayName', message: 'First and Last Name is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.email) {
        const err = {field: 'email', message: 'Email Address is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.password) {
        const err = {field: 'password', message: 'Password is required.'}
        this.setState({formErr: err})
        return
        }
      const user = this.props.me.self
      const { error } = await this.props.me.saveProfile(form)
      if (error) {
        switch (error)
        {
          case 'username-in-use':
          {
            const err = {field: 'username', message: 'Username is in use by another account.'}
            this.setState({formErr: err})
          }
          break
          case 'email-in-use':
          {
            const err = {field: 'email', message: 'Email address is in use by another account.'}
            this.setState({formErr: err})
          }
          return
          case 'phone-number-in-use':
          {
            const err = {field: 'phoneNumber', message: 'Phone number is in use by another account.'}
            this.setState({formErr: err})
          }
          return 
        }
      }
      */
    }
  }

  cancelSignUp = async () => {
    this.clearForm()
    if (!this.props.me.self) {
      //await this.props.me.signInAnonymously()
    } else {
      await delay(0.3)
    }
    if (!this.handlePostSignIn()) {
      if (this.props.me.self) {
        this.ui.gotoApp()
      }
    }
  }

  clearForm = (excludePhoneNumber) => {
    const { form } = this.state
    if (!excludePhoneNumber) {
      form.phoneNumber = ''
    }
    form.verified = false
    form.verificationCode = ''
    form.onVerificationCodeInput = null
    this.state.formErr = ''
    this.forceUpdate()
  }

  handlePostSignIn = () => {
    let then = this.postSignIn
    if (then) {
      this.postSignIn = null
      const { resolve, reject } = then
      resolve()
    }
  }
  
  setUI = ui => {
    this.ui = ui
    if (ui) {
      ui.gotoApp()
    }
  }

  startSignIn = async () => {
    await delay(0.3)
    if (this.props.me.isSignedInAnonymously()) {
      this.ui.gotoPhoneNumberInput()
    } else {
      this.ui.gotoAccount()
    }
  }

  ensureSignedIn = async () => {
    //debugger
    if (!this.props.me.isSignedIn()) {
      await new Promise((resolve, reject) => {
        this.postSignIn = { resolve, reject }
        //debugger
        this.startSignIn()
      })
    }
    //debugger
    return this.props.me.isSignedIn()
  }

  buyPacks = async () => {
    await this.openCart()
  }

  onCreatePaymentMethod = paymentMethod => {
    this.paymentMethod = paymentMethod
  }

  onPaymentStatusChange = updates => {
    console.log("paymentStatusChange", updates)
    //debugger
    const { paymentMethodError, paymentComplete } = updates
    this.setState({
      paymentMethodError
    })
    if (paymentComplete) {
    }
  }

  stack = []

  buy = async product => {
    this.setState({
      paymentMethodError: null
    })
    await this.ensureSignedIn()
    this.ui.gotoPurchase()
    if (this.props.me.isSignedIn()) {
      if (this.props.me.isNative()) {
        const outcome = await this.props.me.purchaseProduct(product.id)
        //this.props.nativeLog("Home outcome: " + JSON.stringify(outcome))
        const { verified, failure } = outcome
        if (verified) {
          //this.ui.gotoApp()
        } 
        return outcome
      } else {
        await delay(0.3)
        const result = await this.paymentMethod.apply()
        const { error } = result
        if (error) {
          this.setState({
            paymentMethodError: error
          })
        }
        return result
      }
    }
  }

  resetPurchase = () => {
    this.setState({
      paymentMethodError: null
    })
    this.purchaseView.reset()
  }

  openCart = async () => {
    this.resetPurchase()
    await delay(0.3)
    this.ui.gotoPurchase()
  }

  cancelCart = async () => {
    await delay(0.3)
    this.resetPurchase()
    this.ui.gotoApp()
  }


  products = {}
  purchases = {}
  usage = 0
  componentDidMount() {
    this.sub0 = this.props.me.observeSelf().subscribe(self => {
      this.removeListeners()
      if (self) {
        this.initListeners()
        this.ui.gotoApp()
      } else {
        this.ui.gotoPhoneNumberInput()
      }
    })
  }

  getPaymentIntent = async product => {
    if (this.testPaymentIntent) {
      if (this.testPaymentIntent.amount !== product.ourPrice * 100) {
        await this.props.me.cancelPaymentIntent(this.testPaymentIntent.id)
        this.testPaymentIntent = null
      }
    }
    if (!this.testPaymentIntent) {
      const { paymentIntent }  = await this.props.me.createPaymentIntent(product.productId)
      //debugger
      this.testPaymentIntent = paymentIntent
      console.log({paymentIntent})
    }
    return this.testPaymentIntent
  }

  componentWillUnmount() {
    this.removeListeners()
    if (this.sub0) {
      this.sub0.unsubscribe()
    }
    if (this.testPaymentIntent) {
      this.props.me.cancelPaymentIntent(this.testPaymentIntent)
    }
  }

  removeListeners = () => {
    if (this.sub1) this.sub1.unsubscribe()
    if (this.sub2) this.sub2.unsubscribe()
    if (this.sub3) this.sub3.unsubscribe()
  }
  
  initListeners = () => {
    this.products  = {}
    this.purchases = {}
    this.state.usage = 0
    this.sub1 = this.props.me.observePurchases().subscribe(change => {
      if (change.type == 'removed') {
        delete this.purchases[change.purchase.id]
      } else {
        this.purchases[change.purchase.id] = change.purchase
      }
      this.forceUpdate()
    })
    this.sub2 = this.props.me.observeProducts().subscribe(change => {
      if (change.type == 'removed') {
        delete this.products[change.product.id]
      } else {
        this.products[change.product.id] = change.product
      }
      this.forceUpdate()
    })
    this.sub3 = this.props.me.observeUsage().subscribe(value => {
      //debugger
      if (value) {
        const { usage } = value
        this.setState({
          usage
        })
      }
    })
  }

  getProducts = () => {
    const products = Object.values(this.products)
    products.sort((x, y) => {
      return y.price - x.price
    })
    return products
  }

  getPurchases = () => {
    return Object.values(this.purchases)
  }

  onPageChanged = () => {
    this.forceUpdate()
  }

  onCreatePurchase = (ref) => {
    this.purchaseView = ref
  }

  render() {
    let appStyle// = this.state.appVisible ? null: { display: 'none' }
    let signUpError
    if (this.state.formErr) {
      signUpError = this.state.formErr.message
    }
    let className = 'userCart'
    if (this.props.me.self && !this.props.me.isSignedInAnonymously()) {
      className += ' userCartSignedIn'
    } else {
      className += ' userCartSignedOut'
    }
    let userCartStyle
    if (this.ui && this.ui.isSignIn()) {
      userCartStyle = {
        display: 'none'
      }
    }
      
    const usage = this.state.usage
    const products = this.getProducts()
    const purchases = this.getPurchases()
    const purchased = purchases.reduce((a, p) => a + p.jokeCount, 0)
    let available = Math.max(purchased - usage, 0)
    let paymentIntent = this.testPaymentIntent
    return <div className='llmaoHome'>
             <div className='poweredBy'>
               <div className='poweredByLeft'>Powered by</div>&nbsp;
               <div className='poweredByRight'>Attunewise.ai</div>
               </div>
             <div className={className} style={userCartStyle}>
               <PlainButton icon={Cart} select={this.openCart}/>
               <PlainButton icon={User} select={this.startSignIn}/>
             </div>
             <UI
               me={this.props.me}
               form={this.state.form}
               onPageChange={this.onPageChanged}
               onChange={this.onChange}
               onCreate={this.setUI}>
            <div className='appPlaceholder' style={appStyle}>
              {this.props.me.self && <Laugh me={this.props.me} purchase={this.buyPacks} available={available}/>}
            </div>
               <SignUp
                 me={this.props.me}
                 form={this.state.form}
                 onChange={this.onChange}
                 next={this.signUp}
                 cancel={this.cancelSignUp}
                 error={signUpError}/>
               <VerificationCode
                 form={this.state.form}
                 onChange={this.onChange}
                 next={this.signUp}
                 error={signUpError}
                 back={this.backToPhoneNumber}
               />
               <Account
                 me={this.props.me}
                 form={this.state.form}
                 onChange={this.onChange}
                 back={this.backToApp}
                 signOut={this.signOut}
                 deleteAccount={this.deleteAccount}
               />
               <Purchase
                 paymentError={this.state.paymentMethodError}
                 reset={this.resetPurchase}
                 key='purchase'
                 onCreate={this.onCreatePurchase}
                 me={this.props.me}
                 purchased={purchased}
                 usage={usage}
                 products={products}
                 onCreatePaymentMethod={this.onCreatePaymentMethod}
                 getPaymentIntent={this.getPaymentIntent}
                 onPaymentStatusChange={this.onPaymentStatusChange}
                 back={this.cancelCart}
                 purchase={this.buy} />
             </UI>
              <div key='recaptcha-fun' id='recaptcha-fun' ref={ref=>{if (ref) this.recaptchaFun=ref}}/>
            </div>
             
  }
}
