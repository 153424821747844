import { Subject, merge } from 'rxjs'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { App } from '../App'
import { Me } from '../../classes/Me'
import { isIPad, isAndroid, isWindows, isSafari, isDesktop, isMobile, isIOS } from '../../classes/Platform.js'
import './index.css'

let portalRef

export const getPortal = () => {
  if (window.getPortalRef) return window.getPortalRef()
  if (portalRef) return portalRef
  return null
}

const firebaseConfig = {
  apiKey: "AIzaSyAczcJOi4bT3FQJElh82FP8esk9RK667hQ",
  authDomain: "llmao-bf512.firebaseapp.com",
  projectId: "llmao-bf512",
  storageBucket: "llmao-bf512.appspot.com",
  messagingSenderId: "764701537052",
  appId: "1:764701537052:web:ed398d995b2243fde7c0d9"
};


const getEnv = () => 'prod'

const getFirebaseConfig = () => {
  return firebaseConfig
}

const config = getFirebaseConfig()

console.log('firebaseConfig', config)
firebase.initializeApp(config)

const me = new Me(firebase, config)

export class Client extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    me.observeSelf().subscribe(self => {
      this.setState({self})
      if (false && !self) {
        me.nativeLogin()
      }
    })
  }

  setPortalRef = ref => {
    if (ref) {
      portalRef = ref
      this.forceUpdate()
    }
  }

  getPlatform = () => {
    let bxPlatName = 'bnClient'

    if (isMobile()) {
      bxPlatName += ' llmaoAppMobile'
    }

    if (isMobile() && !me.isNative()) {
      bxPlatName += ' llmaoAppMobileWeb'
    }

    if (isIOS()) {
      bxPlatName += ' llmaoAppIOS'
    }

    if (isAndroid()) {
      bxPlatName += ' llmaoAppAndroid'
    }

    if(isDesktop() || isIPad()) {
      bxPlatName += ' llmaoAppDesktop'
    }

    if (isSafari()) {
      bxPlatName += ' llmaoAppSafari'
    }

    if (isWindows()) {
      bxPlatName += ' llmaoAppWindows'
    }

    if (me.isNative()) {
      bxPlatName += ' llmaoAppNative'
    }

    return(bxPlatName);
  }

  render () {
    const self = this.state.self
    return <div className={this.getPlatform()} ref={this.setPortalRef}>
        <App self={self} me={me}/>
    </div>
  }
}
