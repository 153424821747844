import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import TSignUpIn from '../../assets/widgets/TSignUpIn.svg'
import TJokePacks from '../../assets/widgets/TJokePacks.svg'
import TVerificationCode from '../../assets/widgets/TVerificationCoide.svg'
import Check from '../../assets/widgets/Tick.svg'
import Next from '../../assets/widgets/Next.svg'
import SignOut from '../../assets/widgets/SignOut.svg'
import Cross from '../../assets/Cross.svg'
import Cancel from '../../assets/widgets/Cancel.svg'
import Back from '../../assets/widgets/Back.svg'
import Buy from '../../assets/widgets/Buy.svg'
import Alert from '../../assets/widgets/Alert.svg'
import HaLLMAO from '../../assets/HaLLMAO.svg'
import { coords, toPoly, randomShuffle, PlainButton, InputField, OutlineText, ButtonSVG } from '../Widgets'
import { JokePack } from '../Purchase'
import { isSafari, isIOS, isMobile, isIPad, isDesktop } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import './index.css'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import ClickAwayListener from 'react-click-away-listener'
import PhoneInput from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { StripePaymentMethodInput} from '../Purchase/PaymentMethod.js'


export class SelectionList extends Component {
  constructor(props) {
    super(props);
  }

  
  selectOption = value => {
    let newValue = value;
    let opt
    if (this.props.multiSelect) {
      const existing = this.props.value.find(x => x == value);
      if (existing) {
        newValue = this.props.value.filter(x => x != value);
      } else {
        newValue = this.props.value.concat([newValue]);
      }
    } else {
      opt = this.props.options.find(opt => opt.value == newValue)
    }
    this.props.select(newValue, opt)
  }
  render() {
    return <div className='uiSelectionList' style={this.props.visible ? null: {display: "none"}}>
      {this.props.options.map((opt, i) => {
        let selected;
        if (this.props.multiSelect) {
          selected = this.props.value.find(x => x == opt.value);
        } else {
          selected = opt.value == this.props.value;
        }
        //debugLog("opt.value: ", opt.value, " = ", this.props.value, " => ", selected);
        const className = "uiSelectionListOption" + (selected ? " uiSelectionListOptionSelected" : "");
        return <div key={opt.name} className={className} onClick={()=>this.selectOption(opt.value)}>
          <div className={'uiSelectionListOptionName'}>{opt.name}</div>
          <div className={'uiSelectionListOptionRight'}>
          <div className={'uiSelectionListOptionValue'}>{opt.value}</div>
            <div key='check' className={'uiSelectionListOptionCheck'} style={selected ? null : { visibility: 'hidden'} }><ReactSVG src={Check}/></div>
          </div>
          </div>
      })}
    </div>
  }
}

const regionCode = {
  "1": "US"
}

const getRegionCode = countryCode => {
  const result = regionCode[String(countryCode)]
  return result
}

const countryCodeOptions = getCountries().map(country => {
  const code = getCountryCallingCode(country)
  const opt = {
    name: en[country],
    value: code
  }
  if (!regionCode[code]) {
    regionCode[code] = country
  }
  return opt;
});

countryCodeOptions.sort((a, b) => {
  if (a.name == "United States") return -1; else if (b.name == "United States") return 1;
  return a.name.localeCompare(b.name);
});


export class Error extends Component {
  render() {
    const { message } = this.props
    const clipPath = `polygon(0px 0px, calc(100% - 0px) 0%, calc(100% - 10px) calc(100% - 10px), 10px 100%)`
    let containerClassName = "plain-button-display-container"
    const display = message ? undefined: 'none'
    return <div className={containerClassName} style={
                  {
                    clipPath,
                    display
                  }
                                                     }>
             <div className='llmaoSignUpError'>
               <ReactSVG src={Alert}/>
               {message}
             </div>
           </div>
  }
}


class PhoneInputField extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  setRef = ref => {
    this.ref = ref
  }

  dismissCountryCodeSelection = () => {
    this.setState({
      selectingCountryCode: false
    })
  }

  onChangeCountryCode = code => {
    this.props.onChange('countryCode', code)
    this.dismissCountryCodeSelection()
  }

  onClickCountryCode = () => {
    if (!this.props.onChange) return
    this.setState({
      selectingCountryCode: !this.state.selectingCountryCode
    })
  }

  renderMenu() {
    return <SelectionList select={this.onChangeCountryCode} options={countryCodeOptions} selected={this.props.form.countryCode} visible={this.state.selectingCountryCode} value={this.props.form.countryCode}/>
  }
  
  render() {
    const props = this.props
    const setRef = x => {
      this.setRef(x)
    }
    let phoneRegionCode = getRegionCode(this.props.form.countryCode || 1)
    const value = this.props.form.phoneNumber || ''
    console.log("value", value)
    console.log("phone region", phoneRegionCode)
    let className = 'bnInputField'
    className += ' bnInputFieldPhoneInput'
    let onChange
    if (this.props.onChange) {
      onChange = value => {
        console.log("new value", value)
        this.props.onChange('phoneNumber', value)
      }
    }
    return <ClickAwayListener onClickAway={this.dismissCountryCodeSelection}>
             <div className="llmaoPhoneInput joke-topic-container">
               {this.props.label &&<div className='jokeTopicLabel'>
                                     <OutlineText text={this.props.label} className='jokeTopicText'/>
                                   </div>}
               <div className="joke-topic" style={{clipPath: this.clipPath}}>
                 <div className='bnInputFieldPhoneInputField'>
                   <div className='bnInputFieldPhoneInputCountryCodeInput'>
                     <div className='bnInputFieldPhoneInputCountryCode' onClick={this.onClickCountryCode}>
                       +{this.props.form.countryCode}
                     </div>
                   </div>
                   <div className={className} onClick={this.dismissCountryCodeSelection}>
                     <PhoneInput
                       initialValueFormat={'national'}
                       country={phoneRegionCode}
                       placeholder={this.props.label}
                       value={value}
                       onChange={onChange}
                       readOnly={!onChange}
                     />
                   </div>
                 </div>
               </div>
               {this.state.selectingCountryCode && this.renderMenu()}
             </div>
           </ClickAwayListener>
  }
}

class Button extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  select = async () => {
    this.setState({shaking: true})
    console.log("shaking")
    await this.props.action()
    console.log("done shaking")
    this.setState({shaking: false})
  }
  
  render() {
    let { icon, action, className, keepFocus } = this.props
    let onClick
    let onMouseDown
    keepFocus = isDesktop() || keepFocus
    if (!keepFocus) {
      onClick = this.select
    } else {
      onMouseDown = (event) => {
        event.preventDefault()
        return this.select()
      }
    }
    let containerClassName = "plain-button-display-container"
    containerClassName += ' plain-button-selectable'

    if (className) {
      containerClassName += ' ' + className
    }
    if (this.state.shaking) {
      containerClassName += ' shake'
    }
    return <div className={containerClassName} onMouseDown={onMouseDown} onClick={onClick}>
             <ReactSVG src={icon}/>
           </div>
  }
}

export class SignUp extends Component {
  constructor (props) {
    super(props)
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  render() {
    return <div className='llmaoSignUp'>
             <div className='llmaoSignUpHeader'>
               <ReactSVG src={TSignUpIn}/>
             </div>
             <div className='llmaoSignUpBody'>
               <PhoneInputField form={this.props.form} onChange={this.props.onChange} label="Phone Number" placeholder='Phone Number'/>
               <div className='llmaoSignUpButtons'>
                 {this.props.me.self && <Button className='button' icon={Cancel} action={this.props.cancel}/>}
                 <Button className='button' icon={Next} action={this.props.next}/>
               </div>
               {this.props.error && <Error message={this.props.error}/>}
             </div>
           </div>
  }
}

export class VerificationCode extends Component {
  constructor (props) {
    super(props)
  }
  componentDidUpdate() {
    if (this.editor && this.props.form.verificationCode !== this.editor.getText()) { //hack
      this.editor.setText(this.props.form.verificationCode) 
    }
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  setEditor = (ref) => {
    this.editor = ref
  }

  onEditorInput = () => {
    this.props.onChange('verificationCode', this.editor.getText())
  }
  
  render() {
    return <div className='llmaoSignUp'>
             <div className='llmaoSignUpHeader'>
               <ReactSVG src={TVerificationCode}/>
             </div>
             <div className='llmaoSignUpBody'>
               <InputField
                 setEditor={this.setEditor}
                 onInput={this.onEditorInput}
                 label="Confirm Code"
                 placeholder='Check your messages'/>
               <div className='llmaoSignUpButtons'>
                 <Button className='button' icon={Back} action={this.props.back}/>
                 <Button className='button' icon={Next} action={this.props.next}/>
               </div>
               {this.props.error && <Error message={this.props.error}/>}
             </div>
           </div>
  }
}


export class Purchase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
      info: ''
    }
    this.clipPath = toPoly(randomShuffle(coords))
  }

  clearInfo = () => {
    this.state.info = null
    this.forceUpdate()
  }

  appStorePurchase = async () => {
    if (!this.props.me.isNative()) {
      if (!this.state.stripeShowing) {
        this.state.stripeShowing = true
        await delay(0.3)
        this.forceUpdate()
        return
      }
      await delay(0.3)
      this.forceUpdate()
    }
    this.clearInfo()
    let product = this.state.purchase
    const result = await this.props.purchase(product)
    const { failure, verified } = result
    this.props.me.nativeLog("Purchase Completed " + JSON.stringify({failure, verified}))
    if (verified) {
      this.setState({purchase: null})
    } else if (failure) {
      if (this.props.me.isNative()) {
        this.setState({
          //purchase: null,
          info: failure
        })
      }
    } else {
      if (this.props.me.isNative()) {
        this.setState({
          purchase: null,
          info: "Unable to verify purchase."
        })
      }
    }
  }

  setScrollRef = ref => {
    this.scroller = ref
  }

  reset = () => {
    this.setState({
      purchase: null,
      stripeShowing: false,
      info: ''
    })
    if (this.scroller) {
      setTimeout(() => {
        this.scroller.scrollTop = 0
      }, 500);
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
    this.sub = this.props.me.observePurchaseOutcome().subscribe(purchase => {
      const { productId, outcome } = purchase
      if (outcome.verified) {
        this.setState({
          info: "Your purchase has been confirmed."
        })
      }
    })
  }

  componentWillUnmount() {
    this.sub.unsubscribe()
  }

  purchase = async product => {
    if (!this.props.me.isNative()) {
      const paymentIntent = await this.props.getPaymentIntent(product)
      debugger
      this.setState({
        error: null,
        paymentIntent
      })
    } else {
      await delay(0.3)
    }
    this.setState({
      error: null,
      purchase: product
    })
  }

  back = async () => {
    await delay(0.3)
    if (this.state.stripeShowing){
      this.setState({
        stripeShowing: false,
      })
    } else {
      this.props.reset()
    }
  }

  renderProducts = () => {
    let products = this.props.products || []
    if (this.props.me.isNative()) {
      if (isIOS()) {
        products = products.filter(x => x.appleId)
      } else {

      }
    }
    return <div className='jokePackPurchasePage'>
             <div className='jokePurchaseTitle'>
               <OutlineText text={'Add Jokes to your Account'} className='purchaseTitleText'/>
             </div>
             <div className='jokePackList'>
               {
                 products.map(product => <JokePack me={this.props.me} product={product} purchase={this.purchase}/>)
               }
             </div>
             <Error message={this.state.info}/>
           </div>
  }

  onCreatePaymentMethod = paymentMethodInput => {
    this.paymethodInput = paymentMethodInput
  }

  renderPurchase = () => {
    let product = this.state.purchase
    let { price } = product
    const paymentIntent = this.state.paymentIntent || ''
    let ourPrice = Math.floor(price * .85 * 100) / 100
    let style = {
      clipPath: this.clipPath
    }
    let appStore
    let appStoreFee
    if (this.props.me.isNative()) {
      appStore = isIOS() ? 'Apple Fee' : 'Google Fee'
      appStoreFee = price - ourPrice
    } else {
      let tmp = ourPrice
      ourPrice = price
      price = tmp
      appStore = 'Discount'
      appStoreFee = ourPrice - price
    }
    let stripePaymentStyle
    let stripePaymentStyle2
    if (!this.props.me.isNative()) {
      if (!this.state.stripeShowing) {
        stripePaymentStyle = {
          display: 'none'
        }
      } else {
        stripePaymentStyle2 = {
          display: 'none'
        }
      }
    }
    let err = this.state.stripeShowing && this.props.paymentError && this.props.paymentError.message
    return <div className='jokePackPurchasePage jokePackPurchasePagePurchase'>
             <div className='jokePurchaseTitle'>
               <OutlineText text={'Payment Summary'} className='purchaseTitleText'/>
             </div>
             <div className='jokePackInvoiceBody'>
               <div className='jokePackInvoiceContainer'>
                 <div className='jokePackInvoice' style={style}>
                   <div className='jokePackInvoiceLineItem'>
                     <div className='jokePackInvoiceLineItemItem'>
                       Price
                     </div>
                     <div className='jokePackInvoiceLineItemPrice'>
                       ${ourPrice.toFixed(2)}
                     </div>
                   </div>
                   <div className='jokePackInvoiceLineItem'>
                     <div className='jokePackInvoiceLineItemItem'>
                       {appStore}
                     </div>
                     <div className='jokePackInvoiceLineItemPrice'>
                       ${appStoreFee.toFixed(2)}
                     </div>
                   </div>
                   <div className='jokePackInvoiceLineItem jokePackLineItemTotal'>
                     <div className='jokePackInvoiceLineItemItem'>
                       Total
                     </div>
                     <div className='jokePackInvoiceLineItemPrice'>
                       ${price.toFixed(2)}
                     </div>
                   </div>
                 </div>
                 <div className='jokePackBuyButton'>
                   <PlainButton icon={Cancel} select={this.back}/>
                   <PlainButton icon={Buy} select={this.appStorePurchase}/>
                 </div>
               </div>
               {
                 err && <div key='paymentError' className='purchaseError'>
                          <Error message={err}/>
                        </div>
               }
               
               {
                 !this.props.me.isNative() && <div key='stripePaymentMethod' style={stripePaymentStyle}>
                                                <StripePaymentMethodInput me={this.props.me} paymentIntent={paymentIntent} onCreate={this.props.onCreatePaymentMethod} onStatusChange={this.props.onPaymentStatusChange}/>
                                              </div>
               }
               <div key='jokePack'>
                 <JokePack me={this.props.me} product={product}/>
               </div>
             </div>
           </div>
  }

  render() {
    let purchased = this.props.purchased || 0
    let currentJokeCount = this.props.usage || 0
    let available = Math.max(purchased - currentJokeCount, 0)
    let back = async () => {
      this.props.reset()
      await this.props.back()
    }
    let content
    if (this.state.purchase) {
      back = this.back
      content = this.renderPurchase()
    } else {
      content = this.renderProducts()
    }
    let bodyClass = 'llmaoSignUpBody'
    let headerStyle
    if (this.state.purchase && this.state.stripeShowing) {
      headerStyle = {
        display: 'none'
      }
      bodyClass += ' bodyStripe'
      if (this.props.paymentError && this.props.paymentError.message) {
        bodyClass += ' bodyStripeError'
      }
    }
    return <div className='llmaoPurchase' ref={this.setScrollRef}>
             <div className='llmaoSignUpHeader'  style={headerStyle}>
               <div><ReactSVG src={TJokePacks}/></div>
               <div className='currentJokeCount'>
                 <OutlineText text={available} className='jokeCountText'/>
                 </div>
             </div>
             <div className={bodyClass}>
               {content}
               {!this.state.purchase &&<div key='backButton' className='llmaoSignUpButtons llmaoSignUpButtonsLeft'>
                 <Button className='button' icon={Back} action={back}/>
              </div>}
               {this.props.error ? <Error message={this.props.error}/> :
               this.state.info && <Error message={this.state.info}/>}
             </div>
           </div>
  }
}


export class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    if (!this.props.me.self) return []
    const form = {
      countryCode: 1,
      phoneNumber: this.props.me.self.phoneNumber
    }
    const onChange = () => {
      this.forceUpdate()
    }
    const onClick = async (e) => {
      e.preventDefault()
      if (this.state.confirming) {
        this.state.shaking = true
        this.forceUpdate()
        await this.props.deleteAccount()
        this.state.shaking = false
        this.forceUpdate()
      } else {
        this.setState({
          confirming: true
        })
      }
    }
    const onClickAway = async e => {
      this.setState({
        confirming: false
      })
    }
    let closeButtonClass = 'llmaoCloseButtonColumns'
    if (this.state.confirming) {
      closeButtonClass += ' llmaoCloseButtonConfirm'
    }
    let outerClassName = 'llmaoCloseButtonOutline'
    if (this.state.shaking) {
      outerClassName += ' shake'
    }
    return <div className='llmaoAccount'>
             <div className='llmaoAccountPhone'>
               <PhoneInputField form={form}  label="Account Number" placeholder="Phone Number"/>
               <div className='llmaoSignUpButtons'>
                 {this.props.me.self && <Button className='button' icon={Back} action={this.props.back}/>}
                 <Button className='button buttonSignOut' icon={SignOut} action={this.props.signOut}/>
               </div>
               {this.props.error && <Error message={this.props.error}/>}
             </div>
             <div className='llmaoAccountCloseContainer'>
               <div className='llmaoAccountCloseText'>
               <OutlineText text={'Permanently Close Account'} className='purchaseTitleText'/>
               </div>
               <div className={outerClassName}>
                 <div className={'llmaoCloseButtonOuter'}>
                   <ClickAwayListener onClickAway={onClickAway}>
                     <div className={closeButtonClass}>
                       <div className='llmaoAccountCloseCross'>
                         <ReactSVG src={Cross}/>
                       </div>
                       <div className={'llmaoCloseButton'} onClick={onClick}>
                         <div className='llmaoAccountCloseButtonTopRow'>
                           <div className='llmaoAccountCloseText'>
                             {'Delete Account' + (this.state.confirming ? ' Confirmation': '' )}
                           </div>
                         </div>
                         <div className='llmaoAccountCloseButtonBottomRow'>
                           All data will be erased from our servers and you will need to create a new account to continue using LLMAO
                           <div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </ClickAwayListener>
                 </div>
               </div>
             </div>
    </div>
  }
}


export class UI extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0,
    }
  }

  set = (key, value) => {
    this.props.onChange(key, value)
  }

  getForm = () => this.props.form

  gotoAccount = () => {
    this.gotoPage(3)
  }

  gotoPhoneNumberInput = () => {
    this.gotoPage(1)
  }
  
  gotoVerificationCodeInput = () => {
    this.gotoPage(2)
  }

  gotoAppIfPossible = async () => {
    this.state.shaking = true
    this.forceUpdate()
    await delay(0.3)
    if (this.props.me.self) {
      this.gotoApp()
    }
    this.state.shaking = false
    this.forceUpdate()
  }

  isSignIn = () => {
    const page = this.state.currentPage
    return page === 2 || page == 1
  }

  isApp = () => {
    const page = this.state.currentPage
    return page === 0
  }

  isPurchase = () => {
    const page = this.state.currentPage
    return page === 3
  }

  gotoPage = n => {
    this.setState({currentPage: n})
    this.props.onPageChange(n)
  }

  gotoApp = () => {
    this.gotoPage(0)
  }

  gotoPurchase = () => {
    this.gotoPage(4)
  }
  
  next = () => {
    this.gotoPage(this.state.currentPage+1)
  }

  back = () => {
    this.gotoPage(this.state.currentPage-1)
  }
  
  componentDidMount() {
    this.props.onCreate(this)
  }

  
  render() {
    let haLLMAO = 'haLLMAO'
    if (this.state.shaking) {
      haLLMAO += ' shake'
    }
    const dur = isDesktop() ? 0.5 : 0.3
    const style = {
      width: `${this.props.children.length * 100}vw`,
      transform: `translate(-${this.state.currentPage*100}vw, 0)`,
      transition: `transform ${dur}s ease-in-out`,
    }
    console.log({style})
    return <div className='llmaoUIContainer'>
             <div className='llmaoUI' style={style}>
               {
                 this.props.children.map((x, i) => {
                  let pageContentStyle = {
                    width: !isMobile() ? '600px' : '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                   return <div key={i} className='llmaoUIPage'>
                            <div className='llmaoUIPageContent' style={pageContentStyle}>
                              <div className='llmaoUIPageContentPadding'>
                                {x}
                              </div>
                            </div>
                          </div>
                })
               }
             </div>
             <div className={haLLMAO} >
               <ReactSVG src={HaLLMAO} onClick={this.gotoAppIfPossible}/>
             </div>
           </div>
  }
}
