import { docData, doc, collectionChanges } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import axios from 'axios'
import { getRandomNumber, randomElement, clone, capitalize, mergeFields, formatAddress } from './Util.js'
import phone from 'phone'
import { isDesktop, isAndroid, isIOS } from './Platform.js'
import { saveAs } from 'file-saver'
import { streamReply } from './Stream.js'

const consoleLog = (...args) => {
  //console.log(...args)
}

const debugLog = (...args) => {
  consoleLog(...args)
}

let server
let isDev = false
if (false) {
  server = 'http://192.168.1.106:8089'
  isDev = true
} else {
  server = 'https://llmao-c4edoicvva-uc.a.run.app'
}
const enableNativeLog = isDev

export class Me {

  nativeInit () {
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (this.isNative()) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
        try {
          const errorOverlay = require('react-native/Libraries/Core/Devtools/parseErrorStack');
          errorOverlay.reportError = (error) => {
            console.warn(error);
          }
        } catch (err) {
          console.error(err)
        }
      }
    }    
  }
  
  isNative = () => {
    return typeof window !== 'undefined' && (window.ReactNativeWebView)
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }

  getIdToken = async () => {
    if (this.self) {
      return await this.self.getIdToken(true)
    }
    return null
  }
  
  getCustomToken = async () => {
    const now = Date.now()
    if(now - this.customTokenTimestamp > 30 * 60 * 1000) {
      this.customTokenTimestamp = now
      this.customToken = null
    }
    if (this.customToken) return this.customToken
    const func = this.firebase.functions().httpsCallable('getCustomToken')
    const result = await func({})
    const { customToken } = result.data
    this.customToken = customToken
    return this.customToken
  }

  constructor (firebase, config) {
    this.firebase = firebase
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    //this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    window.observeContactOnline = this.observeContactOnline
    this.nativeInit()
    window.blockInput = () => {
    }
    window.unblockInput = () => {
    }
    //window.addEventListener('resize', this.applyOrientation)
    window.postMessage = this.onNativeMessage
    this.nativeInit()
  }

  applyOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.orient = 'portrait'
    } else {
      this.orient = 'landscape'
    }
    this.orientSubject.next(this.orient)
  }
  
  reqs = []
  callId = 0
  nativeCall = (type, data) => {
    const id = ++this.callId
    return new Promise(resolve => {
      this.reqs[id] = resolve
      const call = {
        type,
      }
      call[type] = data
      this.sendNativeMessage({
        type: 'call',
        reqId: id,
        call
      })
    })
  }

  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall({
        type: 'location'
      }).then(response => {
        return response.coords
      })
    }
    return getCurrentPosition()
  }

  saveAs = async (blob, filename) => {
    if (window.ReactNativeWebView) {
      const storage = this.firebase.storage()
      const ref = storage.ref(`Uploads/${this.self.uid}/${filename}`)
      await ref.put(blob)
      const url = await ref.getDownloadURL()
      await this.nativeCall('downloadFile', {
        url: url,
        name: filename,
        mimeType: blob.type
      })
      await ref.delete()
    } else {
      saveAs(blob, filename)
    }
  }

  notificationSubject = new Subject()
  credsSubject = new Subject()
  urlSubject = new Subject()
  
  nativeLog = (...args) => {
    if (!enableNativeLog) return
    let msg = ''
    let sep = ''
    for (const arg of args) {
      msg += sep
      if ((typeof arg) === 'object') {
        msg += JSON.stringify(arg, null, ' ')
      } else {
        msg += arg
      }
      sep = ' '
    }
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      console.log(msg)
    }
  }

  validatePurchase = async arg => {
    const { success, failure } = arg
    this.nativeLog("validatePurchase!!!!")
    if (success) {
      let { transactionId, verificationResultIOS, transactionReceipt } = success
      this.nativeLog("validatePurchase: " + JSON.stringify({transactionId}))
      try {
        let func
        let receiptData
        if (isAndroid()) {
          func = this.getFunc("verifyGoogleReceipt")
          transactionReceipt = atob(transactionReceipt)
          receiptData = transactionReceipt
        } else if (isIOS()) {
          func = this.getFunc("verifyAppleReceipt")
          receiptData = verificationResultIOS
        } else {
          throw new Error("not a supported platform")
        }
        const response = await func({transactionId, packageName: "com.badnano.llmao", receiptData})
        this.nativeLog("GOT RESPONSE: " + JSON.stringify(response.data, null, ' '))
        return response.data
      } catch (err) {
        this.nativeLog("GOT ERROR: " + err.message)
        return { failure: err.message}
      }
    } else {
      return { failure }
    }
  }

  purchaseSubject = new Subject()

  observePurchaseOutcome = () => {
    return this.purchaseSubject
  }

  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    consoleLog('onNativeMessage', json)
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message + ": " + json)
      return
    }
    if (msg.type === 'call') {
      const {call} = msg
      const { reqId, op } = call
      const arg = call[op]
      switch (op) {
        case 'validatePurchase':
          const apply = async () => {
            let resolved
            let rejected
            try {
              resolved = await this.validatePurchase(arg)
              //this.nativeLog("resolved: " + JSON.stringify(resolved, null, ' '))
            } catch (err) {
              console.error(err)
              rejected = err.message
            }
            const msg = {
              type: 'return'
            }
            msg['return'] = {
              reqId,
              resolved,
              rejected
            }
            this.sendNativeMessage(msg)
          }
          apply()
          break
        default:
          this.sendNativeMessage({
            type: 'return',
            'return': {
              reqId,
              rejected: "No such operation: " + op
            }
          })
      }
    } else if (msg.type === 'token') {
      try {
        this.saveToken(msg.token)
      } catch (err) {
        console.error(err)
        this.nativeLog('saveToken ' +err.message)
      }
    } else if (msg.type === 'purchase') {
      const { productId, outcome } = msg
      this.purchaseSubject.next({productId, outcome})
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea

      this.nativeLog("window.safeAreaInsets: "+JSON.stringify(window.safeAreaInsets));
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'response') {
      this.nativeLog('response ' + JSON.stringify(msg))
      const resolve = this.reqs[msg.reqId]
      if (resolve) {
        delete this.reqs[msg.reqId]
        resolve(msg.response)
      }
    }
  }

  utcOffset = -(new Date().getTimezoneOffset()*60*1000)

  saveToken = async token => {
    const firebase = this.firebase
    const db = firebase.firestore()
    const c = db.collection('NotifToken')
    const ref = c.doc(this.self.uid)
    const { utcOffset } = this
    await ref.set({
      token,
      utcOffset,
    })
  }

  setStatusBarColor = color => {
    consoleLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }

  likes = {}
  likesSubject = new Subject()

  initListeners = () => {
    this.likesSub = this.observeLikesImpl().subscribe(change => {
      const { type, like } = change
      const { jokeId, uid } = like
      if (type == 'removed') {
        delete this.likes[jokeId]
      }
      else {
        this.likes[jokeId] = true
      }
      this.likesSubject.next({
        type,
        jokeId
      })
    })
  }
  
  removeListeners = () => {
    if (this.likesSub) {
      this.likesSub.unsubscribe()
      this.likesSub = null
    }
  }

  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      this.self = user
      return
    }
    this.referralCode = undefined
    this.apiKey = undefined
    this.self = user
    consoleLog('self', user)
    this.removeListeners()
    this.selfSubject.next(user)
    if (this.self) {
      if (this.isNative()) {
        const {email, phoneNumber} = this.self
        const creds = {
          type: 'login',
          phoneNumber,
        }
        //alert('login ' + JSON.stringify(creds))
        this.sendNativeMessage(creds)
      }
      this.firebase.firestore().collection('Admin').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isAdmin = docs.length > 0
        debugger
      }).catch(ignored => {
        debugger
      })
      this.firebase.firestore().collection('Review').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isReview = docs.length > 0
      }).catch(ignored => {
      })
      this.initListeners()
      this.checkForComplimentaryJokes()
    } else {
      this.signInAnonymously()
    }
  }

  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.getFunc('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.getFunc('phoneNumberExists')
    //debugger
    try {
      const result = await func({phoneNumber})
      return result.data
    } catch (err) {
      //debugger
      throw err
    }
  }

  getToken = () => {
    if (this.self) {
      return this.self.getIdToken(false)
    }
    return null
  }

  getFunc = name => {
    return async (data) => {
      const token = await this.getToken()
      const url = `${server}/${name}`
      consoleLog('name', data)
      const response = await axios.post(url, data, {
        headers: {
          Authorization: 'Bearer '  + token
        }
      })
      consoleLog("result", name, response.data)
      return response
    }
  }
  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    consoleLog("signin in")
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    localStorage.setItem("signedIn", "yes")
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  isSignedIn = () => {
    const user = this.firebase.auth().currentUser
    const result = user && !user.isAnonymous
    return result
  }

  isSignedInAnonymously = () => {
    const user = this.firebase.auth().currentUser
    const result =  user && user.isAnonymous
    consoleLog("isAnonymous", result)
    return result
  }

  signInAnonymously = async () => {
    return await this.firebase.auth().signInAnonymously()
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    const { exists }  = await this.phoneNumberExists(phoneNumber)
    if (!exists && await this.isSignedInAnonymously()) {
      return await this.firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, recaptcha)
    }
    const result = await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
    localStorage.setItem("signedIn", "yes")
    return result
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  deleteAccount = async () => {
    const func = this.getFunc('deleteAccount')
    await func({})
    localStorage.removeItem("signedIn")
    await this.signOut()
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.creds = null
    this.isAdmin = false
    localStorage.setItem("signedOut", "yes")
    this.sendNativeMessage({
      type: 'signOut'
    })
    this.removeListeners()
    this.selfSubject.next(null)
    await this.firebase.auth().signOut()
  }

  getJokesRef = () => this.firebase.firestore().collection('Jokes')
  getLikesRef = () => this.firebase.firestore().collection('Likes')

  tellJoke = async ({id, topic, ts}, opts) => {
    const { onContent, onDone, onError } = opts
    const token = await this.getToken()
    const url = `${server}/tellJoke`
    const utcOffset = this.utcOffset
    return streamReply(url, {id, topic, utcOffset, ts}, token, { onContent, onDone, onError })
  }

  discussJoke = async ({id, transcript, topic, onContent, onDone, onError}) => {
    const func = this.getFunc("discussJoke")
    const response = await func({id, transcript, topic})
    return response.data
  }

  searchJokes = async ({ searchTerm, isYourJokes }) => {
    const fun = await this.getFunc('searchJokes')
    const response = await fun({q: searchTerm, isYourJokes})
    const { results } = response.data
    console.log("Search results", results)
    for (const result of results) {
      result.liked = this.likes[result.id]
    }
    return { results }
  }

  deleteJoke = async (id) => {
    const fun = await this.getFunc('deleteJoke')
    await fun({id})
  }

  shareJoke = async (id) => {
    const fun = await this.getFunc('shareJoke')
    await fun({id})
    debugger
  }

  
  likeJoke = async (joke) => {
    const fun = await this.getFunc('likeJoke')
    await fun({id: joke.id})
    const hadLiked = joke.liked
    const likes = joke.likes || 0
    if (hadLiked) {
      joke.likes = likes - 1
    } else {
      joke.likes = likes + 1
    }
    joke.liked = !joke.liked
  }

  getRandomDocuments = async (existing, N, tries) => {
    tries = tries || 0
    const randomNum = getRandomNumber()
    const db = this.firebase.firestore()
    const c = db.collection('Jokes')
    //debugger
    let q = c.where('shared', '==', true).where('randomField', '>', randomNum)
    q = q.orderBy('randomField', 'asc'),
    q = q.limit(N)
    let { docs } = await q.get()
    docs = docs.filter(x => !existing[x.id])
    if (docs.length < N && tries < 2) {
      docs = docs.concat(await this.getRandomDocuments(existing, N - docs.length, tries+1))
    }
    return docs
  }

  virtualTs = 0

  getTopLaughsOld = async (type, existing, limit) => {
    const docs = await this.getRandomDocuments(existing, limit)
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      data.liked = this.likes[data.id]
      data.ts = ++this.virtualTs
      return data
    })
  }

  getTopLaughs = async (likes, ts, limit) => {
    const db = this.firebase.firestore()
    let q = db.collection('Jokes').where('shared', '==', true)
    q = q.orderBy('likes', 'desc')
    q = q.orderBy('ts', 'desc')
    debugger
    if (likes || ts) {
      q = q.startAfter(likes, ts)
    }
    q = q.limit(limit)
    let { docs } = await q.get()
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      data.liked = this.likes[data.id]
      return data
    })
  }

  getRecent = async (ts, limit) => {
    const db = this.firebase.firestore()
    const c = db.collection('Jokes').where('shared', '==', true)
    let q = c.orderBy('ts', 'desc')
    if (ts) {
      q = q.startAfter(ts)
    }
    q = q.limit(limit || 10)
    let { docs } = await q.get()
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      data.liked = this.likes[data.id]
      return data
    })
  }
  
  observeLikesImpl = () => {
    let q = this.getLikesRef()
    q = q.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      console.log('likes', changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          like: data
        }
      })
    }))
  }

  observeLikes = () => {
    const likes = Object.keys(this.likes)
    return concat(from(likes.map(id => {
      return {
        type: "added",
        jokeId: id
      }
    })), this.likesSubject)
  }


  observeRecent = ({limit}) => {
    let q = this.getJokesRef().where('shared', '==', true)
    q = q.orderBy('ts', 'desc')
    q = q.limit(limit || 1)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.liked = this.likes[data.id]
        return {
          type,
          joke: data
        }
      })
    }))
  }

  observeTopJokes = ({limit}) => {
    let q = this.getJokesRef().where('shared', '==', true)
    q = q.orderBy('likes', 'desc')
    //q = q.limit(limit || 1)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.liked = this.likes[data.id]
        return {
          type,
          joke: data
        }
      })
    }))
  }

    

  observeJokes = (opts) => {
    opts = opts || {}
    const { limit, uid } = opts
    let q = this.getJokesRef()
    q = q.where('uid', '==', this.self.uid)
    q = q.orderBy('ts', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      console.log(changes.length)
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.liked = this.likes[data.id]
        return {
          type,
          joke: data
        }
      })
    }))
  }

  observeProducts = () => {
    const db = this.firebase.firestore()
    const c = db.collection('Products')
    return collectionChanges(c).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          product: data
        }
      })
    }))
  }

  purchaseProduct = async productId => {
    return new Promise(async (resolve, reject) => {
      this.pendingPurchase = {resolve, reject}
      if (this.isNative()) {
        const outcome = await this.nativeCall('purchase', productId)
        this.nativeLog("purchase outcome: " + JSON.stringify(outcome))
        if (!outcome) {
          resolve({ failure: 'Transaction verification in progress.' })
        } else {
          resolve(outcome)
        }
      } else {
        // simulate purchase
        setTimeout(() => {
          this.validatePurchase({
            transactionId: 'test',
            transactionReceipt: 'test'
          })
        }, 1000)
      }
    })
  }

  observeUsage = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Usage').doc(this.self.uid)
    return docData(ref).pipe(map(x => {
      if (!x || !x.usage) {
        return { usage: 0 }
      }
      return x
    }))
  }

  observePurchases = () => {
    const db = this.firebase.firestore()
    const c = db.collection('Purchases')
    const q = c.where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          purchase: data
        }
      })
    }))
  }

  checkForComplimentaryJokes = async () => {
    if (localStorage.getItem('consumedFreeJokes')) {
      return
    }
    const func = this.getFunc("consumeFreeJokes")
    await func({})
    localStorage.setItem('consumedFreeJokes', 'yes')
  }

  // stripe related

  createPaymentIntent = async productId => {
    const func = this.getFunc('createPaymentIntent')
    const result = await func({productId})
    return result.data
  }

  cancelPaymentIntent = async (id) => {
    const func = this.getFunc('cancelPaymentIntent')
    const result = await func({id})
    return result.data
  }

  
}

