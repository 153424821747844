
export const streamReply = (url, data, token, opts) => {
  const { onContent, onDone, onError } = opts
  const xhr = new XMLHttpRequest();
  console.log('POST', url, data, opts)
  xhr.open('POST', url)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.setRequestHeader('Authorization', 'Bearer ' + token)
  let contentLength = 0
  xhr.onprogress = e => {
    const content = xhr.responseText
    console.log("onprogress", content)
    contentLength += content.length
    onContent(content)
  }
  xhr.onreadystatechange = () => {
    var _a, _b;
    if (xhr.readyState === XMLHttpRequest.DONE) {
      const status = xhr.status;
      // In local files, status is 0 upon success in Mozilla Firefox
      // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readystatechange_event#examples
      if (status === 0 || (status >= 200 && status < 400)) {
        onDone(xhr);
      }
      else {
        onError(new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`), xhr.status, xhr);
      }
    }
  }
  xhr.onerror = (event) => {
    onError(new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`), xhr.status, xhr)
  }
  xhr.send(JSON.stringify(data))
  return xhr
}

