import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Client } from './components/Client'
import './index.css'

ReactDOM.render(
  <BrowserRouter>
    { <Client /> }
  </BrowserRouter>,
  document.getElementById('root')
);


