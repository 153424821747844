import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isDesktop } from '../../classes/Platform.js'
import { randomShuffle, coords, toPoly, OutlineText, ButtonSVG, InputField, PlainButton, CategoryButton } from '../Widgets'
import Back from '../../assets/widgets/Back.svg'
import Alert from '../../assets/widgets/Alert.svg'
import Pack from '../../assets/widgets/Pack.svg'
import './index.css'

export class JokePack extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.clipPath = toPoly(randomShuffle(coords))
  }

  purchase = async () => {
    if (!this.props.purchase) return
    if (this.state.shaking) return
    this.state.shaking = true
    this.forceUpdate()
    this.setState({shaking: true})
    let { product } = this.props
    const outcome = await this.props.purchase(product)
    this.setState({shaking: false})
    if (outcome) {
      const { failure } = outcome
      if (failure) {
        console.error("purchase failed")
      }
    }
  }
  
  render() {
    let { product, amount, price } = this.props
    if (product) {
      amount = product.jokeCount
      price = product.price
    }
    const clipPath = this.clipPath
    let innerStyle = {
      clipPath,
    }
    let outerStyle = {
      clipPath
    }
    const formatAmount = amount => {
      let formatted = amount
      const b = Math.floor(amount / 1000000000)
      const m = Math.floor(amount / 1000000)
      const ks = Math.floor(amount / 1000)
      if (b > 0) {
        formatted = b + 'B'
      } else if (m > 0) {
        formatted = m + 'M'
      } else if (ks > 0) {
        formatted = ks  + 'K'
      }
      return formatted
    }
    const formattedUSD = price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    let className = 'jokePackContainer'
    if (this.state.shaking) {
      className += ' shake'
    }
    
    return <div key={product.id} className={className} onClick={this.purchase}>
             <div className='jokePackOuter' style={outerStyle}>
               <div className='jokePackInner' style={innerStyle}>
                 <div className='jokePackLeft'>
                   <div className='jokePackIcon'>
                     <ReactSVG src={Pack}/>
                   </div>
                   <div className='jokePackAmount'>
                     <div className='jokePackAmountNumber'>{formatAmount(amount)}</div><div className='jokePackAmountType'>&nbsp;jokes</div>
                   </div>
                 </div>
                 <div className='jokePackUSD'>
                   {formattedUSD} USD
                 </div>
               </div>             
             </div>
           </div>
  }
}
