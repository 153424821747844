import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isDesktop } from '../../classes/Platform.js'
import { Home } from '../Home'
import FaceImg from '../../assets/Face.png'
import HahaImg from '../../assets/Haha.png'
import './App.css'
import './index.css'

export const JokeSplash = props => {
  let className = 'jokeSplashContainer'
  if (props.lighten) {
    className += ' jokeSplashContainer-lighten'
  }
  return <div className={className}>
          <img className="joke-app-top-right" src={HahaImg}/>
          <img className="joke-app-bottom-left" src={FaceImg}/>
         </div>
}



export class App extends Component {
  render() {
    let style
    if (window.safeAreaInsets) {
      const { top, left, bottom, right} = window.safeAreaInsets
      style = {
        marginTop: top,
        paddingLeft: left,
        paddingRight: right,
        marginBottom: bottom
      }
    }
    return <div className='joke-app-container'>
             <JokeSplash lighten/>
             <div className='joke-app-container-container'>
               <div className="joke-app" style={style}>
                 <Home me={this.props.me}/>
               </div>
             </div>
           </div>
  }
}

