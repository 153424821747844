import React, { Component } from 'react'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import Account from '../../assets/widgets/Account.svg'
import Alert from '../../assets/widgets/Alert.svg'
import Back from '../../assets/widgets/Back.svg'
import Buy from '../../assets/widgets/Buy.svg'
import Cross from '../../assets/widgets/Cross.svg'
import Next from '../../assets/widgets/Next.svg'
import Pack from '../../assets/widgets/Pack.svg'
import SignOut from '../../assets/widgets/SignOut.svg'
import SoloCross from '../../assets/widgets/SoloCross.svg'
import TJokePacks from '../../assets/widgets/TJokePacks.svg'
import TSignUpIn from '../../assets/widgets/TSignUpIn.svg'
import TVerificationCode from '../../assets/widgets/TVerificationCoide.svg'
import Tick from '../../assets/widgets/Tick.svg'
import { isIOS, isMobile, isIPad, isDesktop } from '../../classes/Platform.js'
import './index.css'

export const coords = [4, 3, 1, 0, 4, 0, 0, 3]
export const toPoly = coords => {
  const [x1, y1, x2, y2, x3, y3, x4, y4] = coords
  return `polygon(${x1}px ${y1}px, calc(100% - ${x2}px) ${y2}px, calc(100% - ${x3}px) calc(100% - ${y3}px), ${x4}px calc(100% - ${y4}px)`
}

export function randomShuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

export const walkDOM = (node, func) => {
  func(node)
  node = node.firstChild;
  while(node) {
    walkDOM(node, func)
    node = node.nextSibling
  }
}

class InputControlInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: "",
      editorWidth: 20,
      editorHeight: 0,
    }
  }

  insertPlainTextAtCaret = (text) => {
    this.pushFocus()
    // Get the caret position in the div
    var caretPos = window.getSelection().getRangeAt(0).startOffset;
    // Create a text node with the text to be inserted
    var textNode = document.createTextNode(text)
    // Get the div
    var div = this.ref
    // Get the range
    var range = window.getSelection().getRangeAt(0)
    range.deleteContents();
    // Insert the text node
    range.insertNode(textNode)
    window.getSelection().collapseToEnd()
    if (text) {
      this.setIsEmpty(false)
    } else {
      this.checkIsEmpty()
    }
    this.popFocus()
  }

  insertImage = url => {
    this.pushFocus()
    const img = document.createElement('img');
    img.src = url
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false); // Collapse the range to the end point of the insert
      
      // Update the selection
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      // If the rangeCount is 0, append the image at the end
      this.ref.appendChild(img);
    }
    this.setIsEmpty(false)
    this.popFocus()
    return img
  }

  focusStack = []

  peekFocus = () => {
    return this.focusStack[this.focusStack.length-1]
  }

  pushFocus = () => {
    this.focusStack.push(this.focused)
    if (!this.focused) {
      this.focus()
    }
  }

  popFocus = () => {
    const wasFocused = this.peekFocus()
    if (wasFocused) {
      this.focus()
    } else {
      this.blur()
    }
    this.focusStack.pop()
  }

  getCaretOffset = () => {
    this.pushFocus()
    const { textBeforeCaret } = this.getTextSurroundingCaret()
    this.popFocus()
    return textBeforeCaret.length
  }

  getTextSurroundingCaret = () => {
    this.pushFocus()
    const range = window.getSelection().getRangeAt(0).cloneRange();
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.ref);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const postCaretRange = range.cloneRange()
    postCaretRange.selectNodeContents(this.ref)
    postCaretRange.setStart(range.endContainer, range.endOffset)
    const result = {
      textBeforeCaret: preCaretRange.toString(),
      textAfterCaret: postCaretRange.toString()
    }
    this.popFocus()
    return result
  }

  getTextSurroundingCaretAsync = async () => {
    this.pushFocus()
    await delay(5.0)
    const range = window.getSelection().getRangeAt(0).cloneRange();
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.ref);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const postCaretRange = range.cloneRange()
    postCaretRange.selectNodeContents(this.ref)
    postCaretRange.setStart(range.endContainer, range.endOffset)
    const result = {
      textBeforeCaret: preCaretRange.toString(),
      textAfterCaret: postCaretRange.toString()
    }
    this.popFocus()
    return result
  }

  scrollToCaret = () => {
    const element = this.ref
    var range = window.getSelection().getRangeAt(0);
    var selectionRect = range.getBoundingClientRect();
    
    var topOfElement = element.getBoundingClientRect().top;
    var bottomOfElement = element.getBoundingClientRect().bottom;
    
    // If the caret is above the top of the element, scroll up
    if (selectionRect.top < topOfElement) {
      element.scrollTop -= (topOfElement - selectionRect.top);
    } else  {
      // If the caret is below the bottom of the element, scroll down
      if (selectionRect.bottom > bottomOfElement) {
        element.scrollTop += (selectionRect.bottom - bottomOfElement);
      }
    }
  }

  endSentence = () => {
    const { textBeforeCaret, textAfterCaret } = this.getTextSurroundingCaret()
    if (!textBeforeCaret.endsWith('.')) {
      this.pushFocus()
      //get the caret position
      if (!textBeforeCaret.trim().endsWith('.')) {
        const before = textBeforeCaret.trim() + '. '      
        this.setText(before + textAfterCaret)
        this.setCaretPosition(before.length)
        this.popFocus()
      }
    }
  }
  
  setCaretPosition = cursorOffset => {
    this.pushFocus()
    const selection = window.getSelection()
    document.execCommand('selectAll')
    selection.collapseToStart()
    while (cursorOffset > 0) {
      selection.modify('move', 'forward', 'character')
      this.scrollToCaret()
      cursorOffset--
    }
    this.popFocus()
    if (isDesktop()) {
      this.focus()
    }
  }

  setCaretPositionDebug = async cursorOffset => {
    ////console.log('setCaretPosition', cursorOffset)
    this.focus()
    const selection = window.getSelection()
    document.execCommand('selectAll')
    await delay(0.5)
    selection.collapseToStart()
    await delay(0.5)
    while (cursorOffset > 0) {
      selection.modify('move', 'forward', 'character')
      this.scrollToCaret()
      cursorOffset--
      await delay(0.2)
    }
  }

  tryDeleteSpaceBeforeCaret = () => {
  }

  insertTextAtCaret = (text, noUpdate, select) => {
    if (this.isEmpty()) {
      return this.setText(text)
    }
    const wasFocused = this.focused
    this.pushFocus()
    let whitespaceBefore = false
    let whitespaceAfter = false
    let prev
    if (!wasFocused) {
      prev = this.getText()
      ////console.log("beforeCaret '" +  prev + "'")
      whitespaceBefore = endsWithWhitespace(prev)
      document.execCommand('selectAll')
      window.getSelection().collapseToEnd()
    } else {
      const { textBeforeCaret, textAfterCaret } = this.getTextSurroundingCaret()
      prev = textBeforeCaret
      whitespaceBefore = endsWithWhitespace(textBeforeCaret)
      whitespaceAfter = startsWithWhitespace(textAfterCaret)
    }
    const insideWord = !whitespaceBefore && !whitespaceAfter
    if (!insideWord) {
      if (!whitespaceBefore && !text.startsWith("'")) {
        text = ' ' + text
      }
      if (!whitespaceAfter) {
        text = text + ' '
      }
    }
    const sel = window.getSelection();
    let range = sel.getRangeAt(0);
    range.deleteContents();
    var el = document.createElement("div");
    el.innerText = text;
    var frag = document.createDocumentFragment(), node, lastNode;
    while ( (node = el.firstChild) ) {
      lastNode = frag.appendChild(node);
    }
    range.insertNode(frag);
    // Preserve the selection
    if (lastNode) {
      range = range.cloneRange();
      range.setStartAfter(lastNode);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      if (!select) {
        sel.collapseToEnd()
      }
    }
    this.scrollToVisible()
    this.popFocus()
    if (text) {
      this.setIsEmpty(false)
    }
  }

  scrollToVisible = () => {
    const div = this.ref

    // Get the current cursor position in the div
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    
    // Calculate the top and bottom positions of the cursor
    const top = rect.top + window.pageYOffset - div.offsetTop;
    const bottom = top + rect.height;
    
    // Check if the cursor is outside the visible area of the div
    if (top < div.scrollTop || bottom > div.scrollTop + div.clientHeight) {
      // Scroll the div to make the cursor visible
      div.scrollTop = top - (div.clientHeight / 2);
    }
  }

  getNode = () => {
    return this.ref;
  }

  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDrop(e);
  }

  init = ()=> {
    if (this.ref) {
      this.resizeObserver = new ResizeObserver(entries => {
        try {
          this.setState({
            editorHeight: this.ref.offsetHeight,
            editorWidth: this.ref.offsetWidth
          }, () => {
            this.onEditorHeightChanged(this.state.editorHeight)
          })
        } catch (err) {
          console.error(err)
        }
      })
      this.resizeObserver.observe(this.ref)
    }
  }

  isFocused= ()=> this.ref && document.activeElement == this.ref;

  selectAll = () => {
    const range = document.createRange();
    range.selectNodeContents(this.ref);
    this.lastSelectionRange = range;
    if (document.activeElement == this.ref) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  // total fucking hack to workaround apple TextDocumentProxy bugs
  onSelectionChange = (e) => {
    if (this.focused) {
      if (document.activeElement === this.ref) {
        const sel = document.getSelection()
        var n = sel.focusNode
        var found = false
        while (n) {
          if (n === this.ref) {
            found = true
            break
          }
          n = n.parentNode
        }
        if (!found) {
          ////console.log('selection change', sel)
          // apple bugs have moved the selection away from the focus node
          // force it back
          if (this.lastSelectionRange) {
            sel.removeAllRanges();
            sel.addRange(this.lastSelectionRange);
          }
        } else {
          this.lastSelectionRange = sel.getRangeAt(0);
        }
      }
    }
  }

  componentDidMount() {
    //document.addEventListener("selectionchange", this.onSelectionChange);
    this.init();
  }
  
  componentWillUnmount() {
    //document.removeEventListener("selectionchange", this.onSelectionChange);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  restoreSelection = () => {
    if (this.lastSelectionRange) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      var range = this.lastSelectionRange;
      sel.addRange(range);
    }
  }

  onFocus=(e)=> {
    this.focused = true;
    this.forceUpdate()
    if (this.focusStack.length > 0) {
      return
    }
    if (this.props.onFocus) this.props.onFocus(e, this, ()=>{});
  }

  onEditorHeightChanged = height => {
    if (this.props.onEditorHeightChanged) {
      this.props.onEditorHeightChanged(height - 21)
    }
  }

  onBlur=(e)=> {
    this.focused = false;
    this.forceUpdate()
    if (this.focusStack.length > 0) {
      return
    }
    if (this.props.onBlur) this.props.onBlur(e, this, ()=>{});
  }

  onKeyDown=(e)=> {
    if (this.props.onKeyDown) this.props.onKeyDown(e, this, ()=>{});
  }

  onKeyUp=(e)=> {
    if (this.props.onKeyUp) this.props.onKeyUp(e, this, ()=>{});
  }

  onDrop=(e)=> {
    //debugger
    if (this.props.onDrop) this.props.onDrop(e, this, ()=>{});
  }

  onPaste=(e)=> {
    //debugger
    if (this.props.onPaste) this.props.onPaste(e, this, ()=>{});
  }

  onPointerDown = e => {
    this.dragging = true
    this.onPointerDrag(e)
  }

  onPointerUp = e => {
    this.dragging = false
  }

  onPointerMove = e => {
    if (this.dragging) this.onPointerDrag(e)
  }

  onPointerDrag = e => {
    if (this.props.onPointerDrag) {
      this.props.onPointerDrag(e, this, () => {})
    }
  }

  focus = () => {
    this.ref.focus();
  }

  blur = () => {
    if (this.ref) this.ref.blur();
  }

  lastIsEmpty = true
  isEmptySubject = new Subject()

  isEmpty = () =>  this.lastIsEmpty

  setIsEmpty = isEmpty => {
    if (this.lastIsEmpty != isEmpty) {
      this.lastIsEmpty = isEmpty
      this.isEmptySubject.next(isEmpty)
      this.forceUpdate()
    }
  }

  checkIsEmpty = () => {
    const isEmpty = this.getIsEmpty()
    this.setIsEmpty(isEmpty)
  }
  
  getIsEmpty = () => {
    if (!this.ref) { 
      return true;
    }
    var isEmpty = true
    try {
      walkDOM(this.ref, node => {
        if (node.nodeType === 3) {
          if (node.nodeValue.length > 0) {
            throw new Error('not-empty')
          }
        } else if (node.nodeName == 'IMG'){
          throw new Error('not-empty')
        } else if (node.nodeName == 'VIDEO'){
          throw new Error('not-empty')
        }
      })
    } catch (ignored) {
      //console.error(ignored)
      isEmpty = false
    }
    return isEmpty
  }

  observeIsEmpty = () => {
    return concat(of(this.lastIsEmpty), this.isEmptySubject)
  }

  checkIsEmptyLater = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.checkIsEmpty, 1000 / 15)
  }

  onInput = (e) => {
    this.checkIsEmptyLater()
    if (this.props.onInput) {
      this.props.onInput(e)
    }
  }

  setRef = ref => {
    this.ref = ref
  }

  setAutocompletes = completions => {
  }

  applyCompletion = completion => {
    this.props.applyCompletion(completion)
  }

  onPaste = e => {
    //debugger
    if (this.props.onPaste) {
      this.props.onPaste(e, () => {})
    }
    if (true || !e.defaultPrevented) {
      e.preventDefault()
      let plainText = e.clipboardData.getData('text/plain')
      if (!plainText) {
        const text = e.clipboardData.getData('text/html')
        plainText = makeTextPlain(text)
      }      
      this.insertPlainTextAtCaret(plainText || '')
      this.onInput()
    }
  }

  render = () => {
    let h = this.state.editorHeight
    let y = 0
    const bgStyle = {height: this.state.editorHeight + 19}
    const offsetStyle = this.props.downward ? undefined : { top: -this.state.editorHeight + h + y }
    const isEmpty = this.props.isEmpty || this.isEmpty
    const crossStyle = (!isEmpty()) ? offsetStyle : { display: 'none' }
    ////console.log('crossStyle', this.isEmpty(), crossStyle)
    let className = 'inputControlInputEditor' +
                           (this.props.downward ? ' inputControlEditorDownward' : '') +
        (this.focused ? ' inputControlEditorFocused' : '')
    if (this.props.disabled) {
      className += ' inputControlEditorDisabled'
    }
    const busy =  this.props.busy
    return <div className={className}>
             <div className='inputControlInputEditorBg' style={bgStyle} />
             <div className='inputControlInputEditorContainer'>
               <div className={'inputControlInputEditorInput'} contentEditable={!this.props.disabled} ref={this.setRef}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyDown={this.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    onDrop={this.onDrop}
                    onPointerMove={this.onPointerMove}
                    onPointerDown={this.onPointerDown}
                    onInput={this.onInput}
                    onPaste={this.onPaste}
              />
             {this.props.placeholder && <div className={'inputControlEditorPlaceholder'} style={this.isEmpty() ? {} : {display: "none"}}>{this.props.placeholder}</div>}</div>
             {
               !busy && !this.props.disabled && this.props.clear && <div key='cross' className='inputControlEditorCross' style={crossStyle} onMouseDown={
                                                                           e => {
                                                                             //debugger
                                                                             e.preventDefault()
                                                                             this.props.clear()
                                                                           }}>
                                                                      <ReactSVG src={Cross}/>
                                                                    </div>
             }
             {busy && <div key='busy' className='inputControlEditorCross bnInputFieldClear'>
              <ReactSVG src={Spin}/>
              </div>}
             
           </div>
  }

  undo = () => {
    document.execCommand("undo");
  }

  setText = text => {
    if (!text) {
      if (this.ref) {
        this.ref.innerHTML = ''
      }
      this.setIsEmpty(true)
      return true
    } else if (this.ref && this.ref.innerText != text) {
      let scrollTop = this.ref.scrollTop
      this.ref.innerText = text
      if (this.focused) {
        // move caret to end
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
      }
      this.ref.scrollTop = scrollTop
      this.props.onInput()
      this.setIsEmpty(!text)
      return true
    }
    return false
  }
  
  clear = () => {
    this.ref.innerText = "";
    this.setIsEmpty(true)
    this.forceUpdate();
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  getHTML=() => {
    return this.ref.innerHTML
  }

  getNode = () => {
    return this.ref
  }

  getText=() => {
    return this.ref.innerText
  }

  doInsert=(html, selectPastedContent) => {
    document.execCommand("insertHTML", false, html);
  }
}




export class InputField extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.clipPath = toPoly(randomShuffle(coords))
  }

  setInput = ref => {
    this.editor = ref
    if (this.props.setEditor) {
      this.props.setEditor(this.editor)
    }
  }

  onEditorHeightChanged = editorHeight => {
    this.setState({editorHeight})
  }


  render() {
    return <div className="joke-topic-container">
             {this.props.label &&<div className='jokeTopicLabel'>
               <OutlineText text={this.props.label} className='jokeTopicText'/>
                                 </div>}
             <div className="joke-topic" style={{
                    clipPath: this.clipPath,
                    height: (this.state.editorHeight|| 0) + 40}
                                               }>
               <InputControlInput
                 isEmpty={this.props.isEmpty}
                 disabled={this.props.disabled}
                 downward={true}
                 ref={this.setInput}
                 onEditorHeightChanged={this.onEditorHeightChanged}
                 onFocus={this.props.onFocus}
                 onBlur={this.props.onBlur}
                 onInput={this.props.onInput}
                 onKeyDown={this.props.onKeyDown}
                 onPaste={this.props.onPaste}
                 onDrop={this.props.onDrop}
                 placeholder={this.props.placeholder}
                 clear={this.props.onClear}
                 busy={this.props.busy}
               />
             </div>
             {this.props.send && <div className='sendButton'>
                                   {this.props.send}
                                 </div>}
           </div>
  }
}

export const OutlineText = props => {
  const { text, className } = props
  return <div className={'outlineText '+ className}>
           <div className='outlineLayer outlineOuter'>
             {text}
           </div>
           <div className='outlineLayer outlineInner'>
             {text}
           </div>
         </div>
              
}

class ButtonSVG extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  injected = svg => {
    if (svg) {
      const viewBoxValue = svg.getAttribute('viewBox')
      const [x, y, w, h] = viewBoxValue.split(" ").map(x => parseFloat(x))
      this.setState({
        x, y, w, h
      }, () => {
        if (this.props.onViewBox) {
          this.props.onViewBox(this.state)
        }
      })
    }
  }

  componentDidMount() {
  }
  
  render() {
    const props = this.props
    const { w, h } = this.state
    let style
    if (false) {
      style = {
        width: w,
        height: h
      }
    }
    return <div className='buttonSvg' onClick={props.onClick} onMouseDown={props.onMouseDown} style={style}>
             <ReactSVG beforeInjection={this.injected} src={props.icon}/>
           </div>
  }
}

export const ButtonSVG1 = props => {
  return <div className='buttonSvg' onClick={props.onClick} onMouseDown={props.onMouseDown}>
           <ReactSVG src={props.icon}/>
         </div>
}

export class PlainButton extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  select = async () => {
    if (this.state.shaking) return
    this.state.shaking = true
    this.forceUpdate()
    console.log("shaking")
    try {
      await this.props.select()
    } catch (err) {
      console.error(err)
    }
    console.log("done shaking")
    this.setState({shaking: false})
  }

  onViewBox = viewBox => {
    //debugger
    this.setState({
      viewBox
    })
  }

  render() {
    let { label, clipPath, className, background, keepFocus } = this.props
    let onClick
    let onMouseDown
    keepFocus = isDesktop() || keepFocus
    let containerClassName = "plain-button-display-container"
    if (this.props.select) {
      if (!keepFocus) {
        onClick = this.select
      } else {
        onMouseDown = (event) => {
          event.preventDefault()
          return this.select()
        }
      }
      containerClassName += ' plain-button-selectable'
    }
    if (className) {
      containerClassName += ' ' + className
    }
    if (this.state.shaking) {
      containerClassName += ' shake'
    }
    let style
    if (false && this.state.viewBox) {
      const { w, h } = this.state.viewBox
      style = {
        width: w,
        height: h
      }
    }
    return <div className={containerClassName} style={style}>
             <ButtonSVG onViewBox={this.onViewBox} icon={this.props.icon} onMouseDown={onMouseDown} onClick={onClick}/>
           </div>
  }
}


export class CategoryButton extends Component {
  state = {}
  select = async () => {
    this.setState({shaking: true})
    console.log("shaking")
    await this.props.select()
    console.log("done shaking")
    this.setState({shaking: false})
  }
  render() {
    const clipPath = this.props.clipPath
    const selected = this.props.selected
    let background
    const { shaking } = this.state
    let className = 'category-button top-laughs'
    if (this.props.className) {
      className += ' ' + this.props.className
    } else {
      background = this.props.selected ? 'rgb(102, 45, 145)' : 'rgb(153, 153, 153)'
    }
    let containerClassName = "category-button-display-container"    
    if (this.props.selected) {
      className += ' category-button-selected'
    } else {
      className += ' category-button-unselected'
      containerClassName += ' category-button-display-container-unselected'
    }
    if (shaking) {
      className += ' shake'
    }
    let onClick
    let onMouseDown
    let keepFocus = isDesktop()
    if (!keepFocus) {
      onClick = this.select
    } else {
      onMouseDown = (event) => {
        event.preventDefault()
        return this.select()
      }
    }
    return <div className={containerClassName}>
             <div className={className} onClick={onClick} onMouseDown={onMouseDown} style={
                       {
                         clipPath,
                         background
                       }
                     }><OutlineText className='category-button-text' text={this.props.label}/></div>
    </div>
  }
}


